/* header */
.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 7vh;
  background-color: #ff8c00;
}

.header-logo {
  padding-left: 5vw;
}

.header-logo img {
  width: 35vw;
  padding-top: 5px;
}

@media only screen and (min-device-width: 1000px) {
  .header-logo img {
    width: 15vw;
    padding-top: 10px;
  }
}
/* header end */

/* content2 */
.content2 {
  display: flex;
  padding: 0px 10vw 5vw 10vw;
  align-items: flex-end;
}
.content2-email-area {
  width: 200px;
}

@media only screen and (min-device-width: 1000px) {
  .content2 {
    padding: 0px 20vw 20px 20vw;
    max-width: 450px;
    margin: 0 auto;
  }
  .content2-email-area {
    width: 340px;
  }
}
/* content2 end */

/* content3 */
.content3 {
  padding: 0px 10vw 10vw 10vw;
}

@media only screen and (min-device-width: 1000px) {
  .content3 {
    padding: 0px 20vw 50px 20vw;
    max-width: 450px;
    margin: 0 auto;
  }
}

/* content3 end */

/* content4 */
.content4 {
  padding: 0px 10vw 5vw 10vw;
}

@media only screen and (min-device-width: 1000px) {
  .content4 {
    padding: 0px 20vw 20px 20vw;
    max-width: 450px;
    margin: 0 auto;
  }
}
/* content4 end */

/* content5 */
.content5-text {
  font-size: 25px;
}
/* content5 end */

/* content6 */
.content6 {
  padding: 0px 10vw 5vw 10vw;
}

.content6-choice-block {
  padding-bottom: 15px;
}

@media only screen and (min-device-width: 1000px) {
  .content6 {
    padding: 0px 20vw 20px 20vw;
    max-width: 450px;
    margin: 0 auto;
  }

  .content6-choice-block {
    padding-bottom: 20px;
  }
}
/* content6 end */

/* content7 */
.content7-text {
  font-size: 25px;
}
/* content7 end */

/* content8 */
.content8 {
  padding: 0px 10vw 5vw 10vw;
  max-width: 450vw;
}

@media only screen and (min-device-width: 1000px) {
  .content8 {
    padding: 0px 20vw 20px 20vw;
    max-width: 450px;
    margin: 0 auto;
  }
}
/* sontent8 end */

/* content9 */
.content9 {
  padding-top: 20px;
  padding-bottom: 20px;
}
/* sontent9 end */
