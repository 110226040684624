/* header */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7vh;
  background-color: #ffffff;
}

.header-logo {
  padding-right: 1vw;
}

.header-logo img {
  width: 35vw;
  padding-top: 5px;
}

.header-logo-link {
  text-decoration: none;
}

.header-create-poll-button {
  padding-left: 20vw;
}

@media only screen and (min-device-width: 1000px) {
  .header-logo img {
    width: 15vw;
    padding-top: 10px;
  }
}
/* header end */

/* content1 */
.content1 {
  width: 100%;
  height: 70vh;
  background-color: #ff8c00;
}

.content1-headline {
  padding: 5px;
  text-align: center;
}

.content1-headline-text {
  color: #ffffff;
  font-weight: bolder;
  font-size: 20px;
  display: inline-block;
  text-align: left;
}

.content1-img-area {
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.content1-img-area::-webkit-scrollbar {
  display: none;
}

.content1-img {
  max-width: auto;
  height: 37vh;
  padding: 2vw;
  border-radius: 15px;
}

.content1-create-poll-button {
  padding-top: 10px;
}

@media only screen and (min-device-width: 1000px) {
  .content1-headline-text {
    font-size: 40px;
  }
  .content1-img {
    width: 10em;
    padding-left: 1em;
    padding-right: 1em;
    margin: 0 auto;
  }

  .content1 {
    height: 90vh;
  }
}
/* content1 end */

/* content2 */
.content2 {
  width: 100%;
  padding-bottom: 20vw;
}

.content2-logo-img {
  max-width: 80vw;
}

.content2-headline {
  padding: 5px;
  text-align: center;
}

.content2-headline-text {
  color: #ff8c00;
  font-weight: bolder;
  font-size: 30px;
  display: inline-block;
  text-align: left;
}

.content2-block-area {
  display: flex;
  justify-content: center;
}

.content2-block {
  background-color: #ff8c00;
  width: 165px;
  height: 165px;
  border-radius: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content2-block-text {
  color: #ffffff;
  font-weight: bolder;
  font-size: 20px;
}

@media only screen and (min-device-width: 1000px) {
  .content2 {
    padding-bottom: 7vw;
  }
  .content2-logo-img {
    max-width: 50vw;
  }
  .content2-headline-text {
    font-size: 40px;
  }
  .content2-block-column {
    display: flex;
    justify-content: center;
  }
}
/* content2 end */

/* content3 */
.content3 {
  padding-bottom: 10vw;
}

.content3-headline {
  padding: 5px;
  text-align: center;
}

.content3-headline-text {
  color: #ff8c00;
  font-weight: bolder;
  font-size: 25px;
  display: inline-block;
  text-align: left;
}

.content3-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content3-img {
  max-width: 40vw;
  height: auto;
  padding: 2vw;
  border-radius: 15px;
}

.content3-text {
  font-size: clamp(16px, 1.8vw, 30px);
  display: inline-block;
  text-align: left;
}

@media only screen and (min-device-width: 1000px) {
  .content3-headline-text {
    font-size: 35px;
  }

  .content3-img {
    max-width: 35vw;
  }
}
/* content3 end */

/* content4 */
.content4-icon-area {
  text-align: left;
  padding-left: 10vw;
}

.content4-icon {
  width: 80px;
  border-radius: 50%;
}

.content4-block {
  text-align: center;
  padding-bottom: 10vw;
}

.content4-block-headline {
  padding-left: 5vw;
  text-align: left;
}

.content4-block-headline-text {
  color: #ff8c00;
  font-weight: bolder;
  font-size: 25px;
  display: inline-block;
  text-align: left;
}

.content4-text-area {
  text-align: center;
}

.content4-text {
  font-size: clamp(16px, 1.8vw, 30px);
  display: inline-block;
  text-align: left;
}

@media only screen and (min-device-width: 1000px) {
  .content4-icon-area {
    padding-left: 20vw;
  }

  .content4-icon {
    width: 100px;
  }
  .content4-block-headline {
    padding-left: 29vw;
  }
  .content4-block-headline-text {
    font-size: 35px;
  }
}
/* content4 end */

/* content5 */
.content5-headline {
  padding: 5px;
  text-align: center;
}

.content5-headline-text {
  color: #ff8c00;
  font-weight: bolder;
  font-size: 25px;
  display: inline-block;
  text-align: left;
}

.content5-acordion {
  padding-bottom: 20px;
  text-align: left;
}

@media only screen and (min-device-width: 1000px) {
  .content5-headline-text {
    font-size: 40px;
  }
  .content5-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .content5-acordion {
    font-size: clamp(16px, 1.8vw, 30px);
    width: 60vw;
  }
}
/* content5 end */

/* content6 */
.content6-headline {
  padding: 5px;
  text-align: center;
}

.content6-headline-text {
  color: #ff8c00;
  font-weight: bolder;
  font-size: 25px;
  display: inline-block;
  text-align: left;
}

.content6 {
  padding-bottom: 20vh;
}

@media only screen and (min-device-width: 1000px) {
  .content6-headline-text {
    font-size: 40px;
  }
}
/* content6 end */

/* footer */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7vh;
  background-color: #d9d9d9;
}

.footer-contact {
  padding-right: 10vw;
}

.footer-contact-link {
  text-decoration: none;
  color: #1e1e1e;
}

.footer-contact-text {
  font-size: 15px;
}

.footer-privacy-terms {
  padding-left: 10vw;
}

.footer-privacy-terms-link {
  text-decoration: none;
  color: #1e1e1e;
}

.footer-privacy-terms-text {
  font-size: 15px;
}

@media only screen and (min-device-width: 1000px) {
  .footer-contact-text {
    font-size: clamp(16px, 1.8vw, 20px);
    width: 60vw;
  }
  .footer-privacy-terms-text {
    font-size: clamp(16px, 1.8vw, 20px);
    width: 60vw;
  }
}
/* footer end */
