/* header */
.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 7vh;
    background-color: #ff8c00;
  }
  
  .header-logo {
    padding-left: 5vw;
  }

  .header-logo img {
    width: 35vw;
    padding-top: 5px;
  }
  
  @media only screen and (min-device-width: 1000px) {
    .header-logo img {
      width: 15vw;
      padding-top: 10px;
    }
  }
  /* header end */

  /* content1 */
.content1 {
  background-color: #ffffff;
  p {
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    padding-block-start: 1vh;
    padding-block-end: 1vh;
  }
}

.content1-text {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (min-device-width: 1000px) {
  .content1-text {
    font-size: clamp(25px, 2.5vw, 40px);
  }
}
/* content1 end */

/* content3 */
.content3 {
  padding-top: 20px;
}
/* sontent3 end */