/* header */
.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 7vh;
  background-color: #ff8c00;
}

.header-logo {
  padding-left: 5vw;
}

.header-logo img {
  width: 35vw;
  padding-top: 5px;
}

@media only screen and (min-device-width: 1000px) {
  .header-logo img {
    width: 15vw;
    padding-top: 10px;
  }
}
/* header end */

/* content1 */
.content1 {
  background-color: #ffffff;
  p {
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    padding-block-start: 1vh;
    padding-block-end: 1vh;
  }
}

.content1-text {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (min-device-width: 1000px) {
  .content1-text {
    font-size: clamp(25px, 2.5vw, 40px);
  }
}
/* content1 end */

/* body */
@media only screen and (min-device-width: 1000px) {
  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
/* body end*/

/* content2 */
.content2 {
  height: 40vh;
  width: 95vw;
  background-color: #ffffff;
}

@media only screen and (min-device-width: 1000px) {
  .content2 {
    max-width: 50vw;
    height: 80vh;
    background-color: #ffffff;
  }
}
/* content2 end */

/* content3 */
.content3-wrapper {
  height: 47vh;
  background-color: #ff8c00;
  p {
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    padding-block-start: 1vh;
    padding-block-end: 1vh;
  }
}

.content3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content3-img {
  max-width: auto;
  max-height: 37vh;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  background-color: #ffffff;
}

@media only screen and (min-device-width: 1000px) {
  .content3-wrapper {
    width: 40vw;
    height: 84vh;
    background-color: #ff8c00;
    
    p {
      margin-block-start: 0em !important;
      margin-block-end: 0em !important;
      padding-block-start: 1vh;
      padding-block-end: 1vh;
      font-size: clamp(15px, 2.5vw, 25px);
    }
  }
  .content3 {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content3-img {
    max-width: 90%;
    max-height: auto;
    border-radius: 15px;
    aspect-ratio: 1 / 1;
  }
}
/* content3 end */