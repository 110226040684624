/* header */
.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 7vh;
    background-color: #ff8c00;
  }
  
  .header-logo {
    padding-left: 5vw;
  }
  
  .header-logo-link {
    text-decoration: none;
    color: #ffffff;
  }
  
  .header-logo img {
    width: 35vw;
    padding-top: 5px;
  }
  
  @media only screen and (min-device-width: 1000px) {
    .header-logo img {
      width: 15vw;
      padding-top: 10px;
    }
  }
/* header end */

/* content1 */
.content1 {
    p {
      margin-block-start: 0em !important;
      margin-block-end: 0em !important;
      padding-block-start: 1vh;
      padding-block-end: 1vh;
      text-decoration: underline;
      text-decoration-thickness: 0.5em;
      text-decoration-color: rgba(255, 140, 0, 0.4);
      text-underline-offset: -0.2em;
      text-decoration-skip-ink: none;
    }
  }
  
  .content1-text {
    font-size: 25px;
    font-weight: bold;
  }
  
  @media only screen and (min-device-width: 1000px) {
    .content1-text {
      font-size: clamp(25px, 2.5vw, 40px);
    }
  }
/* content1 end */

/* content2 */
.content2 {
    padding: 0px 10vw 10vw 10vw;
  }
  
@media only screen and (min-device-width: 1000px) {
  .content2 {
    padding: 0px 20vw 50px 20vw;
    max-width: 450px;
    margin: 0 auto;
  }
}
/* content2 end */

/* content3 */
.content3 {
  padding: 0px 10vw 10vw 10vw;
}

@media only screen and (min-device-width: 1000px) {
  .content3 {
    padding: 0px 20vw 50px 20vw;
    max-width: 450px;
    margin: 0 auto;
  }
}
/* content3 end */

/* content4 */
.content4 {
  padding: 0px 10vw 10vw 10vw;
}

@media only screen and (min-device-width: 1000px) {
  .content4 {
    padding: 0px 20vw 50px 20vw;
    max-width: 450px;
    margin: 0 auto;
  }
}
/* content4 end */

/* footer */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7vh;
  background-color: #d9d9d9;
  position: absolute;
  bottom: 0;
}

.footer-top {
  padding-right: 10vw;
}

.footer-top-link {
  text-decoration: none;
  color: #1e1e1e;
}

.footer-top-text {
  font-size: 15px;
}

.footer-privacy-terms {
  padding-left: 10vw;
}

.footer-privacy-terms-link {
  text-decoration: none;
  color: #1e1e1e;
}

.footer-privacy-terms-text {
  font-size: 15px;
}

@media only screen and (min-device-width: 1000px) {
  .footer-top-text {
    font-size: clamp(16px, 1.8vw, 20px);
    width: 60vw;
  }
  .footer-privacy-terms-text {
    font-size: clamp(16px, 1.8vw, 20px);
    width: 60vw;
  }
}
/* footer end */
