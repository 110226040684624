/* header */
.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 7vh;
    background-color: #ffffff;
}

.header-logo {
    padding-left: 5vw;
}
  
.header-logo img {
  width: 35vw;
  padding-top: 5px;
}

@media only screen and (min-device-width: 1000px) {
  .header-logo img {
    width: 15vw;
    padding-top: 10px;
  }
}
/* header end */

/* body */
.body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93vh;
    background-color: #ff8c00;
}
/* body end*/
  
/* content1 */
.content1-text {
    color: #ffffff;
    font-size: 25px;
}
@media only screen and (min-device-width: 1000px) {
    .content1-text {
      font-size: clamp(25px, 2.5vw, 40px);
    }
  }
/* content1 */

/* content2 */
.content2 {
    margin-top: 10vh;
}
.content2-text {
    color: #ffffff;
}
@media only screen and (min-device-width: 1000px) {
    .content2-text {
      font-size: clamp(25px, 2.5vw, 30px);
    }
  }
/* content2 */