/* header */
.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 7vh;
    background-color: #ff8c00;
  }
  
  .header-logo {
    padding-left: 5vw;
  }
  
  .header-logo-link {
    text-decoration: none;
    color: #ffffff;
  }
  .header-logo img {
    width: 35vw;
    padding-top: 5px;
  }
  
  @media only screen and (min-device-width: 1000px) {
    .header-logo img {
      width: 15vw;
      padding-top: 10px;
    }
  }
/* header end */

.content1-wrapper {
    display: flex;
    justify-content: center;
}

/* content1 */
.content1 {
    max-width: 90vw;
    margin-bottom: 10vh;
    p {
      margin-block-start: 0em !important;
      margin-block-end: 0em !important;
      padding-block-start: 1vh;
      padding-block-end: 1vh;
      text-align: left;
    }
    h1 {
        font-size: 25px;
        text-decoration: underline;
        text-decoration-thickness: 0.5em;
        text-decoration-color: rgba(255, 140, 0, 0.4);
        text-underline-offset: -0.2em;
        text-decoration-skip-ink: none;
    }
    h2 {
        font-size: 20px;
    }
    @media only screen and (min-device-width: 1000px) {
        h1 {
            font-size: clamp(25px, 2.5vw, 40px);
        }
        h2 {
            font-size: clamp(15px, 2.5vw, 30px);
        }
      }
  }

  @media only screen and (min-device-width: 1000px) {
    .content1 {
        max-width: 60vw;
    }
  }
/* content1 end */

/* footer */
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7vh;
    background-color: #d9d9d9;
    position: absolute;
  }
  
  .footer-top {
    padding-right: 10vw;
  }
  
  .footer-top-link {
    text-decoration: none;
    color: #1e1e1e;
  }
  
  .footer-top-text {
    font-size: 15px;
  }
  
  .footer-contact {
    padding-left: 10vw;
  }
  
  .footer-contact-link {
    text-decoration: none;
    color: #1e1e1e;
  }
  
  .footer-contact-text {
    font-size: 15px;
  }
  
  @media only screen and (min-device-width: 1000px) {
    .footer-top-text {
      font-size: clamp(16px, 1.8vw, 20px);
      width: 60vw;
    }
    .footer-contact-text {
      font-size: clamp(16px, 1.8vw, 20px);
      width: 60vw;
    }
  }
  /* footer end */